import {groq} from 'next-sanity'
import {getLocaleText} from 'src/data/queries/fragments'

export const tabMenuQuery = () => groq`
...,
'title':${getLocaleText('title')},
'links':links[]{
    ...,
    'linkText':${getLocaleText('linkText')},
    'link':link->{slug}
}
`
