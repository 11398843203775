import {groq} from 'next-sanity'
import {getLocaleText, getPortableText, imageQuery} from 'src/data/queries/fragments'

export const twoUpSectionQuery = () => groq`
  ...,

'title':${getLocaleText('title')},
'heading': ${getLocaleText('heading')},
'text':${getPortableText('text')},

miniTextBlocks[]-> {
  ...,

  'title': ${getLocaleText('title')},
  'text':${getPortableText('text')},

  'image':image${imageQuery}
}
`
