import {AccessibleImage, Author} from '@customTypes/generated-types'
import {IBlogCategory, ResolvedSanityReferences} from '@customTypes/types'
import {useSanityImage} from '@hooks/useSanityImage'
import clsx from 'clsx'
import Image from 'next/image'
import {FC, memo} from 'react'
import {st} from 'src/locales/TranslationHelper'
import {Byline} from '../../shared/Byline'
import {Link} from '../../shared/Link'

interface IBlogPostCard {
  title: string
  teaser: string
  author?: ResolvedSanityReferences<Author>
  image: ResolvedSanityReferences<AccessibleImage>
  category?: IBlogCategory
  href: string
  size: 'sm' | 'md' | 'lg'
  className?: string
  publishedAt?: string
}

const BlogPostCard: FC<IBlogPostCard> = ({
  title,
  teaser,
  author,
  image,
  category,
  href,
  size,
  className,
  publishedAt,
}) => {
  const imageProps = useSanityImage(image.asset)
  const imageSizes = {
    sm: '(min-width: 1024px) 300px, (min-width: 400px) 405px, 300px',
    md: '(min-width: 1024px) 405px, (min-width: 400px) 405px, 300px',
    lg: '(min-width: 1024px) 650px, (min-width: 400px) 405px, 300px',
  }
  return (
    <article
      className={clsx(
        'flex w-full  rounded-xl p-3 relative hover:bg-purple-10  hover:translate-y-[-5px] transition-all ease-in-out duration-[250] will-change-transform',
        size === 'lg' ? 'flex-col lg:items-center lg:flex-row  lg:gap-12 ' : 'flex-col',
        className,
      )}
    >
      <div className={clsx('flex-shrink-0 w-full', size === 'lg' && '  lg:max-w-[650px]')}>
        <div className=" aspect-w-16 aspect-h-9">
          <Image
            {...imageProps}
            layout="fill"
            sizes={imageSizes[size]}
            loader={imageProps.loader}
            className="rounded-lg"
            blurDataURL={imageProps.blurDataURL}
            placeholder="blur"
            quality={90}
            objectFit="cover"
            alt={image.altText}
          />
        </div>
      </div>
      <div
        className={clsx('flex flex-col justify-between flex-1 pt-6 ', size === 'lg' && 'lg:justify-start lg:flex-0')}
      >
        <div className={clsx(size === 'lg' ? 'flex-0' : 'flex-1')}>
          {category?.slug && (
            <Link
              href={category?.slug}
              className="relative text-sm z-[1] font-medium rounded-full text-purple-50 hover:text-purple-90 capitalize"
            >
              <span className="sr-only">{st.blogArchive.viewAll()}</span>
              {category.title} <span className="sr-only">{st.blogArchive.blogPosts()}</span>
            </Link>
          )}

          <div className="block mt-2">
            <h3>
              <Link
                prefetch={false}
                href={href}
                className="text-xl font-semibold !text-gray-900 card-link-full line-clamp-2"
              >
                {title}
              </Link>
            </h3>
            <p className="mt-3 text-base text-gray-600 line-clamp-3">{teaser}</p>
          </div>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex-shrink-0 hidden md:block">
            {author && (
              <Byline
                name={author.name}
                size="mini"
                profileImage={author.profileImage.asset}
                url={author.slug?.current}
                publishedAt={publishedAt}
                cardLink={true}
              />
            )}
          </div>
        </div>
      </div>
    </article>
  )
}
export default memo(BlogPostCard)
