import ResourcesPageIcon from '@assets/icons/resources-icon.svg'
import {Link} from '@components/shared/Link'
import clsx from 'clsx'
import {useRouter} from 'next/router'
import {FC} from 'react'
import {st} from 'src/locales/TranslationHelper'

interface IResourcesTabMenuProps {
  className?: string
}

export const ResourcesTabMenu: FC<IResourcesTabMenuProps> = ({className}) => {
  const router = useRouter()
  const getLinkColor = (linkSlug: string) => {
    if (linkSlug === router.pathname) {
      return 'text-purple-70'
    }
    return '!text-purple-50 '
  }

  return (
    <nav className={clsx('flex justify-between mt-4 spare-container ', className)}>
      {/* Icon & Page Name */}
      <div className="flex items-center gap-3">
        <ResourcesPageIcon width="36px" height="36px" />
        <p className="font-bold text-gray-900 uppercase ">{st.resourcesPage.tabMenu.title()}</p>
      </div>

      {/* Links */}
      <ul className="flex items-center gap-6 overflow-x-auto max-w-[200px] md:max-w-none">
        <li>
          <Link href="/resources" className={clsx('nav-item-text p-0 whitespace-nowrap', getLinkColor('/resources'))}>
            {st.resourcesPage.tabMenu.overview()}
          </Link>
        </li>
        <li>
          <Link href="/blog" className={clsx('nav-item-text p-0 whitespace-nowrap', getLinkColor('/blog'))}>
            {st.resourcesPage.tabMenu.blog()}
          </Link>
        </li>
        <li>
          <Link
            href="/customer-stories"
            className={clsx('nav-item-text p-0 whitespace-nowrap', getLinkColor('/customer-stories'))}
          >
            {st.resourcesPage.tabMenu.customerStories()}
          </Link>
        </li>
      </ul>
    </nav>
  )
}
