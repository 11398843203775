import {ISanityIcon} from '@customTypes/types'
import {useSanityImage} from '@hooks/useSanityImage'
import {Trigger} from '@radix-ui/react-tabs'
import Image from 'next/image'
import {FC} from 'react'
interface ITabProps {
  icon?: ISanityIcon
  title: string
  text?: string
  value: string
}

export const Tab: FC<ITabProps> = ({icon, title, text, value}) => {
  const imageProps = useSanityImage(icon?.asset, {enableBlurUp: false})

  return (
    <Trigger
      value={value}
      className="flex flex-col justify-center w-full p-4 text-left transition-colors duration-200 ease-out rounded-lg lg:w-96 lg:h-20 slide-show-tab"
    >
      <div className="flex items-center">
        <Image
          {...imageProps}
          layout="fixed"
          width={16}
          height={16}
          alt=""
          placeholder="empty"
          key={icon?.asset?._ref}
        />
        <p className="ml-2 text-lg font-semibold">{title}</p>
      </div>
      <p className="hidden w-full font-medium text-gray-600 md:block">{text}</p>
    </Trigger>
  )
}
