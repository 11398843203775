import {groq} from 'next-sanity'
import {getLocaleText, imageQuery} from 'src/data/queries/fragments'

export const customersSectionQuery = () => groq`
  _id, _type, backgroundStyle,

'title':${getLocaleText('title')},

customerLogos[]-> {
  _id,
  'logo':logo${imageQuery}
}
`
