import {tabMenuQuery} from '@components/TabMenu'
import {groq} from 'next-sanity'
import {getLocaleText, getPortableText, metaDataQuery} from 'src/data/queries/fragments'
import {newsletterSignupSectionQuery} from '../NewsletterSignUpSection'
export const simplePageSectionQuery = () => groq`
  ...,
${metaDataQuery},
'subtitle': ${getLocaleText('subtitle')},
'text':${getPortableText('text')},
'tabMenu':tabMenu->{${tabMenuQuery()}},
'newsLetterSignUp':newsLetterSignUp->{${newsletterSignupSectionQuery()}}
`
