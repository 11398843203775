import {SectionHeader} from '@components/shared/Section/SectionHeader'
import {SectionWrapper} from '@components/shared/SectionWrapper'
import {EventsSection as IEventsSection} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import React from 'react'
import {Event} from './Event'

export interface IEventsSectionProps {
  data: ResolvedSanityReferences<IEventsSection>
}

export const EventsSection = ({data}: IEventsSectionProps) => (
  <SectionWrapper>
    <div className=" spare-container">
      {data.title && <SectionHeader className="text-center">{data.title}</SectionHeader>}
      <ul className="grid gap-20 mt-20 md:grid-cols-2 lg:grid-cols-1">
        {data.events?.map(event => (
          <Event key={event._id} {...event} />
        ))}
      </ul>
    </div>
  </SectionWrapper>
)
