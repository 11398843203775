import {AccessibleImage} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {useSanityImage} from '@hooks/useSanityImage'
import Image from 'next/image'
import {FC} from 'react'
import {PriorityLoadImageThreshold} from 'src/constants/image'
import {BlockItem, IBlockItemProps} from './BlockItem'

interface IBlockProps {
  image?: ResolvedSanityReferences<AccessibleImage>
  heading: string
  items: IBlockItemProps[]
  sectionNumber: number
}

export const Block: FC<IBlockProps> = ({image, heading, items, sectionNumber}) => {
  const imageProps = useSanityImage(image?.asset)
  return (
    <div className="flex flex-col">
      {image?.asset && (
        <div>
          <Image
            {...imageProps}
            layout="responsive"
            sizes="(min-width: 1024px) 500px, (min-width: 768px) 500px, 100vw"
            placeholder="blur"
            priority={sectionNumber <= PriorityLoadImageThreshold.SectionNumber}
            blurDataURL={imageProps.blurDataURL}
            alt={image.altText}
            key={image.asset._ref}
          />
        </div>
      )}
      <h2 className="mt-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">{heading}</h2>
      <ul className="flex flex-col gap-4 mt-8">
        {items.map(item => (
          <BlockItem key={item.text} icon={item.icon} text={item.text} />
        ))}
      </ul>
    </div>
  )
}
