import {groq} from 'next-sanity'
import {getLocaleText, getPortableText, imageQuery} from 'src/data/queries/fragments'

export const slideshowSectionQuery = () => groq`
  ...,

'title':${getLocaleText('title')},
'text':${getPortableText('text')},

slideshowItems[] {
  ...,

  'title':${getLocaleText('title')},
  'subtitle':${getLocaleText('subtitle')},

  'image':image${imageQuery}
}
`
