import React from 'react'
// eslint-disable-next-line no-restricted-imports
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
export const CalendarSkeletonLoader = () => (
  <div className="xl:w-[798px]  min-h-[602px] border mx-auto rounded-lg grid lg:grid-cols-2 mt-[60px]">
    <div className="flex flex-col xl:border-r  max-w-[336px] xl:max-w-none xl:m-0 mx-auto border-b xl:border-b-0">
      <div className="h-[173px] flex items-center justify-center border-b">
        <Skeleton height={100} width={300} />
      </div>
      <div className="p-[30px]">
        {/* Profile Image */}
        <div className="lg:w-[300px]">
          <Skeleton height={50} className="mt-6" />
        </div>
        {/* Sub Header */}
        <Skeleton width={100} className="mt-6" />
        {/* Header */}

        <div className="lg:w-[300px]">
          <Skeleton height={40} className="mt-6" />
        </div>
        {/* Location & Time */}
        <div className="flex flex-col gap-2 mt-8">
          <div className="flex items-end gap-4">
            <Skeleton width={25} height={25} />
            <Skeleton width={100} />
          </div>
          <div className="flex items-end gap-4">
            <Skeleton width={25} height={25} />
            <Skeleton width={100} />
          </div>
        </div>

        {/* Details Paragraph */}
        <div className="mt-6">{/* <Skeleton width={320} count={3} /> */}</div>
      </div>
    </div>
    <div>
      <div className="flex flex-col p-[30px] max-w-[336px] xl:max-w-none xl:m-0 mx-auto">
        {/* Header*/}
        <Skeleton width={200} height={30} className="mt-6" />

        {/* Calendar Container*/}
        <div className="mt-6 ">
          <Skeleton width={100} height={20} className="mt-6" />

          {/* Calendar */}
          <div className="grid grid-cols-7 gap-2 mt-5">
            {/* Days */}

            {Array(7)
              .fill(1)
              .map((_, idx) => (
                <Skeleton key={idx} height={15} width={35} />
              ))}

            {/* Dates */}
            {Array(35)
              .fill(1)
              .map((_, idx) => (
                <Skeleton key={idx} height={35} width={35} />
              ))}
          </div>

          {/* Timezone Selector */}
          <Skeleton width={250} height={20} className="mt-10" />
        </div>
      </div>
    </div>
  </div>
)
