import {groq} from 'next-sanity'
import {getLocaleText, getPortableText, imageQuery} from 'src/data/queries/fragments'

export const eventSectionQuery = () => groq`
  ...,

'title':${getLocaleText('title')},

events[]-> {
  ...,

  button {
    ..., 
    'buttonText':${getLocaleText('buttonText')},
    modalForm->
  },

  'description':${getPortableText('description')},

  'image':image${imageQuery},
  
  'name':${getLocaleText('name')}, 
  'teaser':${getLocaleText('teaser')},
  'location':${getLocaleText('location')}
}
`
