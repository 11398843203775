import {Link} from '@components/shared/Link'
import {IBlogCategory} from '@customTypes/types'
import {ChevronDownIcon} from '@heroicons/react/solid'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import clsx from 'clsx'
import {FC} from 'react'
import {st} from 'src/locales/TranslationHelper'

interface ICategoryDropdownProps {
  categories: IBlogCategory[]
  className?: string
}

export const CategoryDropdown: FC<ICategoryDropdownProps> = ({categories, className}) => (
  <NavigationMenu.Root className={clsx('text-left  w-full ', className)}>
    <NavigationMenu.List>
      <NavigationMenu.Item className="relative">
        <NavigationMenu.Trigger className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium bg-white border rounded-md text-purple-90 border-purple-20 ">
          {st.blogArchive.selectCategory()}
          <ChevronDownIcon className="w-5 h-5" />
        </NavigationMenu.Trigger>

        <NavigationMenu.Content className="absolute flex flex-col w-full gap-4 bg-white z-[5] p-4 shadow-lg rounded">
          {categories.map(category => (
            <Link href={category.slug} key={category.title}>
              {category.title}
            </Link>
          ))}
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    </NavigationMenu.List>
  </NavigationMenu.Root>
)
