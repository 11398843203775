import {groq} from 'next-sanity'
import {getLocaleText, getPortableText, imageQuery} from 'src/data/queries/fragments'

export const comparisonBlockQuery = () => groq`
  ...,

iconTextLeft[] {
  ..., 'text':${getPortableText('text')}
},

iconTextRight[] {
  ..., 'text':${getPortableText('text')}
},

'imageLeft':imageLeft${imageQuery},
'imageRight':imageRight${imageQuery},

'titleLeft':${getLocaleText('titleLeft')},
'titleRight':${getLocaleText('titleRight')}
`
