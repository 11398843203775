import {SectionWrapper} from '@components/shared/SectionWrapper'
import {CalendlySection as ICalendlySection} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import Script from 'next/script'
import React, {FC, useState} from 'react'
import {CalendarSkeletonLoader} from './CalendarSkeletonLoader'

export interface ICalendlySectionProps {
  data: ResolvedSanityReferences<ICalendlySection>
}

export const CalendlySection: FC<ICalendlySectionProps> = ({data}) => {
  const [calendarLoaded, setCalendarLoaded] = useState(false)

  // Builds a string based off the flags toggled in Sanity
  const constructCalendlyUrl = (url: string) => {
    const constructedURL = new URL(url)

    if (data.hideCookieBanner && data.hideEventTypeDetails) {
      constructedURL.searchParams.append('hide_event_type_details', '1')
      constructedURL.searchParams.append('hide_gdpr_banner', '1')
    } else if (data.hideCookieBanner || data.hideEventTypeDetails) {
      data.hideEventTypeDetails
        ? constructedURL.searchParams.append('hide_event_type_details', '1')
        : constructedURL.searchParams.append('hide_gdpr_banner', '1')
    }

    return constructedURL.href
  }

  return (
    <SectionWrapper id={data?.id}>
      <div className="spare-container">
        {data?.subHeader && (
          <p className="text-base font-bold tracking-wide text-center text-indigo-600 uppercase ">{data.subHeader}</p>
        )}
        {data?.header && (
          <h1 className="mt-1 text-4xl font-extrabold text-center text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {data.header}
          </h1>
        )}
        <div
          className="w-full rounded-lg  min-h-[1150px] md:min-h-[1250px] xl:min-h-[700px] mt-6 lg:m-0  "
          id="calendly-container"
          style={{minWidth: '320px', width: '100%', height: '700px'}}
        >
          {!calendarLoaded && <CalendarSkeletonLoader />}
        </div>
        <Script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          strategy="afterInteractive"
          onLoad={() => {
            window.Calendly.initInlineWidget({
              url: constructCalendlyUrl(data.calendlyUrl),
              parentElement: document.getElementById('calendly-container'),
            })
            setCalendarLoaded(true)
          }}
        ></Script>
      </div>
    </SectionWrapper>
  )
}
