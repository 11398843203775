import {Link} from '@components/shared/Link'
import {AccessibleImage} from '@customTypes/generated-types'
import {useSanityImage} from '@hooks/useSanityImage'
import clsx from 'clsx'
import {default as NextImage} from 'next/image'
import {FC} from 'react'
import {SanityReference} from 'sanity-codegen'

interface IBylineProps {
  name: string
  profileImage?: SanityReference<AccessibleImage>
  role?: string
  url?: string
  publishedAt?: string
  size?: 'mini' | 'full'
  alt?: string
  cardLink?: boolean
}

export const Byline: FC<IBylineProps> = ({name, profileImage, role, url, publishedAt, size, alt = '', cardLink}) => {
  let parsedDate = ''
  const imageProps = useSanityImage(profileImage)

  // Type narrowing
  if (publishedAt) {
    parsedDate = new Date(publishedAt).toLocaleDateString('en-CA', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return (
    <div className="flex flex-row items-center mt-2">
      {profileImage && (
        <div className={clsx('mr-3 overflow-hidden border rounded-full ', size === 'mini' ? 'w-10 h-10' : 'w-16 h-16')}>
          <NextImage
            {...imageProps}
            layout="responsive"
            className=""
            objectFit="cover"
            width={100}
            height={100}
            sizes="100px"
            alt={alt}
            quality={100}
          />
        </div>
      )}

      <div className={clsx('flex flex-col')}>
        {url ? (
          <Link
            prefetch={false}
            href={url}
            className={clsx('text-sm font-[550] no-underline hover:text-purple-70', {
              'text-sm': size === 'mini',
              '!text-xl': size === 'full',
              'card-link-small': cardLink,
            })}
          >
            {name}
          </Link>
        ) : (
          <span
            className={clsx('text-sm font-bold m-0 text-gray-900', {
              'text-sm': size === 'mini',
              'text-xl': size === 'full',
            })}
          >
            {name}
          </span>
        )}

        {role && (
          <span className={`${size === 'mini' ? 'text-xs' : 'text-sm'} block font-normal text-gray-70 py-[2px]`}>
            {role}
          </span>
        )}

        {parsedDate && (
          <time dateTime={publishedAt} className="text-sm font-normal text-gray-70">
            {parsedDate}
          </time>
        )}
      </div>
    </div>
  )
}
