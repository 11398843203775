import {SectionWrapper} from '@components/shared/SectionWrapper'
import {TextBannerSection as ITextBannerSection} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {PortableTextComponents} from '@helpers/PortableTextSerializer'
import {useSanityImage} from '@hooks/useSanityImage'
import {PortableText} from '@portabletext/react'
import clsx from 'clsx'
import Image from 'next/image'
import React, {FC} from 'react'

export interface ITextBannerSectionProps {
  data: ResolvedSanityReferences<ITextBannerSection>
}

export const TextBannerSection: FC<ITextBannerSectionProps> = ({data}) => {
  const imageProps = useSanityImage(data.image?.asset)

  return (
    <SectionWrapper className="text-white bg-purple-50" id={data?.id}>
      <div
        className={clsx(
          'spare-container',
          data?.image && 'grid sm:grid-cols-[1fr_400px] justify-items-center items-center gap-8 ',
        )}
      >
        {/* Text */}
        <div>
          {data.title && <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{data.title}</h2>}
          {data.bodyText && (
            <div className="mt-3 prose text-white md:prose-2xl max-w-none">
              <PortableText value={data.bodyText} components={PortableTextComponents} />
            </div>
          )}
        </div>

        {/* Image */}
        {data.image && (
          <div className="w-full">
            <div className="relative block w-full">
              <Image
                {...imageProps}
                layout="responsive"
                sizes="(min-width: 1024px) 600px, (min-width: 768px) 500px, 100vw"
                alt={data.image.altText}
                placeholder="blur"
                blurDataURL={imageProps.blurDataURL}
              />
            </div>
          </div>
        )}
      </div>
    </SectionWrapper>
  )
}
