import {NewsletterSignUpSection} from '@components/Sections/NewsletterSignUpSection'
import {LocaleBlock, NewsletterSignup} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {PortableTextComponents} from '@helpers/PortableTextSerializer'
import {PortableText} from '@portabletext/react'
import {FC} from 'react'

interface ISimplePageSectionProps {
  title: string
  subTitle?: string
  text: ResolvedSanityReferences<LocaleBlock>
  newsLetterSignUp?: ResolvedSanityReferences<NewsletterSignup>
}

export const SimplePageSection: FC<ISimplePageSectionProps> = ({title, subTitle, text, newsLetterSignUp}) => (
  <div className="col-span-3">
    <div className="mt-16 spare-container">
      <div className="pb-12 mx-auto mt-6 prose prose-lg ">
        <div className="text-center ">
          <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {title}
          </h1>
          <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">{subTitle}</p>
        </div>
        <PortableText value={text} components={PortableTextComponents} />
      </div>
    </div>
    {newsLetterSignUp && <NewsletterSignUpSection data={newsLetterSignUp} />}
  </div>
)
