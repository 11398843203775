import {InlineForm} from '@components/InlineForm'
import {SectionWrapper} from '@components/shared/SectionWrapper'
import {NewsletterSignup} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import clsx from 'clsx'
import {FC} from 'react'

export interface INewsletterSignUpSectionProps {
  data: ResolvedSanityReferences<NewsletterSignup>
  sectionNumber?: number
}

export const NewsletterSignUpSection: FC<INewsletterSignUpSectionProps> = ({data}) => {
  const getColor = (color: string) => {
    switch (color) {
      case 'white':
        return {bg: 'bg-white', heading: 'text-gray-900', text: 'text-gray-600'}
      case 'light':
        return {bg: 'bg-purple-10', heading: 'text-gray-90', text: 'text-gray-70'}
      case 'dark':
        return {bg: 'bg-purple-70', heading: 'text-white', text: 'text-purple-30'}
      case 'highlight':
        return {bg: 'bg-yellow-20', heading: 'text-purple-70', text: 'text-purple-70'}
      default:
        return {bg: 'bg-purple-70', heading: 'text-white', text: 'text-purple-30'}
    }
  }

  return (
    <SectionWrapper className={clsx(getColor(data.backgroundStyle).bg, 'mb-0')} id={data?.id}>
      <div className="spare-container xl:flex xl:items-center">
        <div className="xl:w-0 xl:flex-1">
          <h2
            className={clsx(
              'text-2xl font-extrabold tracking-tight sm:text-3xl',
              getColor(data.backgroundStyle).heading,
            )}
          >
            {data.title}
          </h2>
          <p className={clsx('max-w-3xl mt-3 text-lg leading-6 ', getColor(data.backgroundStyle).text)}>{data.text}</p>
        </div>
        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <InlineForm
            inlineHubspotFormId={data.inlineForm?.inlineHubspotFormId}
            buttonText={data.inlineForm?.buttonText}
            backgroundStyle={data.backgroundStyle}
          />
        </div>
      </div>
    </SectionWrapper>
  )
}
