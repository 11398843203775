import {Link} from '@components/shared/Link'
import {SectionWrapper} from '@components/shared/SectionWrapper'
import {Slideshow} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {List, Root} from '@radix-ui/react-tabs'
import clsx from 'clsx'
import {FC} from 'react'
import {st} from 'src/locales/TranslationHelper'
import {Tab} from './Tab'
import {TabContent} from './TabContent'

export interface ITabSlideShowSectionProps {
  data: ResolvedSanityReferences<Slideshow>
  sectionNumber: number
}

export const TabSlideShowSection: FC<ITabSlideShowSectionProps> = ({data, sectionNumber}) => (
  <SectionWrapper className={clsx(data.backgroundStyle === 'dark' ? 'bg-purple-10' : 'bg-white')} id={data?.id}>
    <div className="w-full spare-container">
      <h2 className="mt-1 mb-3 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{data.title}</h2>
      {data?.text && data?.url ? (
        <Link href={data.url} className="flex items-center text-lg font-semibold w-fit">
          {data.text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </Link>
      ) : data?.text ? (
        <p className="mt-3 text-lg">{data.text} </p>
      ) : null}
      <div>
        <Root
          defaultValue={data.slideshowItems[0]._key}
          orientation="vertical"
          className="flex flex-col-reverse w-full gap-6 mt-6 lg:flex-row "
        >
          <List
            aria-label={st.tabbedSlideShow.ariaLabel()}
            className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1 auto-rows-min"
          >
            {data.slideshowItems.map(item => (
              <Tab key={item._key} value={item._key} title={item.title} text={item?.subtitle} icon={item?.icon} />
            ))}
          </List>
          {data.slideshowItems.map(content => (
            <TabContent key={content._key} src={content.image} value={content._key} sectionNumber={sectionNumber} />
          ))}
        </Root>
      </div>
    </div>
  </SectionWrapper>
)
