import {groq} from 'next-sanity'
import {getLocaleText, getPortableText} from 'src/data/queries/fragments'

export const newsletterSignupSectionQuery = () => groq`
  ...,

'text': ${getPortableText('text')},
'title':${getLocaleText('title')},

inlineForm-> {
  ..., 'buttonText':${getLocaleText('buttonText')}
}
`
