import {AccessibleImage} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {useSanityImage} from '@hooks/useSanityImage'
import {Content} from '@radix-ui/react-tabs'
import Image from 'next/image'
import {FC} from 'react'
interface ITabImageProps {
  src: ResolvedSanityReferences<AccessibleImage>
  value: string
  sectionNumber?: number
}

export const TabContent: FC<ITabImageProps> = ({src, value}) => {
  const imageProps = useSanityImage(src?.asset)
  return (
    <Content value={value}>
      <div className="relative block w-full lg:w-[550px] xl:w-[810px] lg:h-[600px]">
        <Image
          {...imageProps}
          layout="responsive"
          sizes="(min-width: 1024px) 800px,  100vw"
          quality={90}
          alt={src.altText}
          placeholder="blur"
          priority={true}
          blurDataURL={imageProps.blurDataURL}
          key={src.asset._ref}
        />
      </div>
    </Content>
  )
}
