import {groq} from 'next-sanity'
import {getLocaleText, getPortableText} from 'src/data/queries/fragments'

export const newsletterWithImageSignupQuery = () => groq`
  ...,

'header':${getLocaleText('header')},
'subHeader':${getLocaleText('subHeader')},
'text':${getPortableText('text')},
form->{..., 
'buttonText':${getLocaleText('buttonText')}, 
followUpForm->
},
image {
  ..., '
  altText':${getLocaleText('altText')}
},


`
