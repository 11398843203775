import clsx from 'clsx'
import {FC} from 'react'

interface ISectionHeaderProps {
  className?: string
}

export const SectionHeader: FC<ISectionHeaderProps> = ({children, className}) => (
  <h2 className={clsx('text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl', className)}>{children}</h2>
)
