import {InlineForm} from '@components/InlineForm'
import {SectionHeader} from '@components/shared/Section/SectionHeader'
import {SectionSubHeader} from '@components/shared/Section/SectionSubHeader'
import {SectionWrapper} from '@components/shared/SectionWrapper'
import {NewsletterWithImageSignup as INewsletterWithImageSignup} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {PortableTextComponents} from '@helpers/PortableTextSerializer'
import {useSanityImage} from '@hooks/useSanityImage'
import {PortableText} from '@portabletext/react'
import clsx from 'clsx'
import Image from 'next/image'
import {FC} from 'react'

export interface INewsletterWithImageSignupProps {
  data: ResolvedSanityReferences<INewsletterWithImageSignup>
}

export const NewsletterWithImageSignup: FC<INewsletterWithImageSignupProps> = ({data}) => {
  const imageProps = useSanityImage(data.image.asset)

  return (
    <SectionWrapper
      backgroundStyle={data.backgroundStyle}
      removeBottomPadding={data?.removeBottomPadding}
      id={data?.id}
    >
      <div className="grid items-center lg:gap-12 lg:grid-cols-2 spare-container">
        {/* Text Group */}
        <div className={data.alignment === 'textRight' ? 'lg:col-start-2 lg:row-start-1' : ''}>
          {data.subHeader && <SectionSubHeader>{data.subHeader}</SectionSubHeader>}
          <SectionHeader className="mt-1">{data.header}</SectionHeader>
          {data.text && (
            <div className="mt-3 prose prose-lg md:prose-xl">
              <PortableText value={data.text} components={PortableTextComponents} />
            </div>
          )}

          <div className="max-w-lg mt-8">
            <InlineForm inlineHubspotFormId={data.form.inlineHubspotFormId} buttonText={data.form.buttonText} />
          </div>
        </div>

        {/* Image */}
        <div
          className={clsx(
            'hidden lg:block rounded-lg overflow-hidden',
            data.alignment === 'textRight' && 'lg:col-start-1 lg:row-start-1',
          )}
        >
          <Image
            {...imageProps}
            quality={80}
            layout="responsive"
            sizes="(min-width: 768px) 600px, 100vw"
            alt={data.image.altText}
            placeholder="blur"
            blurDataURL={imageProps.blurDataURL}
            lazyBoundary="300px"
          />
        </div>
      </div>
    </SectionWrapper>
  )
}
