import {SectionHeader} from '@components/shared/Section/SectionHeader'
import {SectionSubHeader} from '@components/shared/Section/SectionSubHeader'
import {SectionWrapper} from '@components/shared/SectionWrapper'
import {YouTubeEmbed} from '@components/YouTubeEmbed'
import {Video} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {PortableTextComponents} from '@helpers/PortableTextSerializer'
import {PortableText} from '@portabletext/react'
import clsx from 'clsx'
import {FC} from 'react'

export interface IVideoSectionProps {
  data: ResolvedSanityReferences<Video>
}

export const VideoSection: FC<IVideoSectionProps> = ({data}) => (
  <SectionWrapper removeBottomPadding={data.removeBottomPadding} backgroundStyle={data.backgroundStyle} id={data?.id}>
    <div className="grid grid-rows-[fit-content_fit-content] spare-container gap-y-8">
      {/* Text Group */}
      <div className={data.centerAlignText ? 'mx-auto text-center' : ''}>
        {data.subHeader && <SectionSubHeader>{data.subHeader}</SectionSubHeader>}
        {data.header && <SectionHeader className="mt-1">{data.header} </SectionHeader>}
        {data.text && (
          <div className={clsx('mt-3 prose prose-lg', data.centerAlignText && 'mx-auto')}>
            <PortableText value={data.text} components={PortableTextComponents} />
          </div>
        )}
      </div>

      {/* Video Component - this component acts as facade so we only load the content on click */}
      <YouTubeEmbed youtubeId={data.youtubeId} />
    </div>
  </SectionWrapper>
)
