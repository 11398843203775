import {SectionWrapper} from '@components/shared/SectionWrapper'
import {ComparisonBlock} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {FC} from 'react'
import {Block} from './Block'

export interface IComparisonBlockSectionProps {
  data: ResolvedSanityReferences<ComparisonBlock>
  sectionNumber: number
}

export const ComparisonBlockSection: FC<IComparisonBlockSectionProps> = ({data, sectionNumber}) => (
  <SectionWrapper backgroundStyle={data.backgroundStyle} id={data?.id}>
    <div className="flex flex-col gap-12 md:gap-none md:grid justify-between grid-cols-[330px_330px] lg:grid-cols-[420px_420px] xl:grid-cols-[500px_500px]  spare-container">
      <Block image={data.imageLeft} heading={data.titleLeft} items={data.iconTextLeft} sectionNumber={sectionNumber} />
      <Block
        image={data.imageRight}
        heading={data.titleRight}
        items={data.iconTextRight}
        sectionNumber={sectionNumber}
      />
    </div>
  </SectionWrapper>
)
