import BlogPostCard from '@components/LegacyComponents/Blog/BlogPostCard'
import {CategoryDropdown} from '@components/LegacyComponents/Blog/CategoryDropdown'
import {ResourcesTabMenu} from '@components/LegacyComponents/ResourcesPage/ResourcesTabMenu'
import {Button} from '@components/shared/DeprecatedButton/Button'
import {Spinner} from '@components/Spinner'
import {IBlogPage, IBlogPostResource, ResolvedSanityReferences} from '@customTypes/types'
import {getPicoSanityClient} from '@lib/sanity.server'
import {useRouter} from 'next/router'
import React, {FC, Fragment, useEffect, useState} from 'react'
import {initialBlogPostsCount, paginatedBlogPostsOffset} from 'src/constants/blogPosts'
import {paginatedBlogPostsQuery} from 'src/data/queries/pageQueries'
import {st} from 'src/locales/TranslationHelper'
import {CategoryGroup} from './CategoryGroup'

interface IAllBlogPostsProps {
  blogPageData: ResolvedSanityReferences<IBlogPage>
  blogCategorySlug?: string
  isPreview: boolean
}

export const AllBlogPosts: FC<IAllBlogPostsProps> = ({blogPageData, blogCategorySlug, isPreview}) => {
  const [blogPosts, setBlogPosts] = useState<Array<ResolvedSanityReferences<IBlogPostResource>>>(blogPageData.posts)
  const [offsetStart, setOffsetStart] = useState(initialBlogPostsCount)
  const [isLoadingPosts, setIsLoadingPosts] = useState(false)
  const {locale} = useRouter()
  const getBlogModulo = (index: number) => index % 6

  useEffect(() => {
    setBlogPosts([...blogPageData.posts])
  }, [blogPageData])

  const loadMoreBlogPosts = async (): Promise<void> => {
    setIsLoadingPosts(true)
    const morePosts: Array<ResolvedSanityReferences<IBlogPostResource>> = await getPicoSanityClient(isPreview).fetch(
      paginatedBlogPostsQuery(blogCategorySlug),
      {
        // We to coalesce here due to how the picoSanityClient constructs the api endpoint the request is made to
        categorySlug: blogCategorySlug ?? '',
        offsetStart,
        offsetEnd: offsetStart + paginatedBlogPostsOffset,
        locale,
      },
    )
    setOffsetStart(prev => (prev += paginatedBlogPostsOffset))

    setBlogPosts(prev => [...prev, ...morePosts])
    setIsLoadingPosts(false)
  }

  // Column widths for each of 3 styles in the alternating 1 x 3 x 2 pattern
  const rowStyles = {
    sm: 'md:col-span-1 lg:col-span-2',
    md: 'md:col-span-1 lg:col-span-3',
    lg: 'md:col-span-1 lg:col-span-6',
  }

  // Determines a 1 x 3 x 2 alternating pattern for blog posts
  const determineRowStyle = (blogPostIndex: number) => {
    switch (getBlogModulo(blogPostIndex)) {
      case 0:
        return 'lg'
      case 2:
        return 'sm'
      case 3:
        return 'sm'
      case 4:
        return 'md'
      case 5:
        return 'md'
      default:
        return 'sm'
    }
  }

  return (
    <>
      <div className="col-span-3 row-start-1 pb-20 bg-purple-10 sm:px-6 lg:pb-28 ">
        <ResourcesTabMenu className="pb-14" />

        <h1 className="text-5xl font-extrabold text-center text-gray-900 ext-4xl ">{st.blogArchive.pageTitle()}</h1>
        <div className="mx-auto mt-8 ">
          {/* Category Selectors */}
          <CategoryGroup categories={blogPageData.categories} />

          {/* Dropdown is hidden on screen larger than desktop */}
          <CategoryDropdown categories={blogPageData.categories} className=" max-w-[320px]  mx-auto z-10 md:hidden" />
        </div>
      </div>

      {/* Grid of Blog Posts */}
      <div className="grid w-full col-span-3 row-start-2 gap-12 pb-12 mt-12 spare-container md:gap-8 lg:gap-7 md:grid-cols-2 lg:grid-cols-6">
        {blogPosts.map((post, idx) => {
          const size = determineRowStyle(idx)
          return (
            <Fragment key={post._id}>
              <BlogPostCard
                title={post.title}
                teaser={post.teaser}
                image={post.image}
                category={post.category}
                className={rowStyles[size]}
                author={post.author}
                size={size}
                publishedAt={post.publishedAt}
                href={post.slug.current}
              />

              {/* Grid divider that splits up each pattern from the next */}
              {(getBlogModulo(idx) === 0 || getBlogModulo(idx) === 3 || getBlogModulo(idx) === 5) && (
                <hr className="hidden lg:block col-span-full h-[1px] w-full my-8" />
              )}
            </Fragment>
          )
        })}
      </div>
      {offsetStart < blogPageData.totalPosts && (
        <div className="flex justify-center col-span-6">
          <Button
            className="flex items-center justify-center !rounded-full min-w-[300px]"
            // This rule as disabled as it complaining about a edge case that doesn't exist since we are using Static Site Rendering
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => loadMoreBlogPosts()}
          >
            {isLoadingPosts ? <Spinner /> : 'Load More Posts'}
          </Button>
        </div>
      )}
    </>
  )
}
