import {Link} from '@components/shared/Link'
import {IBlogCategory} from '@customTypes/types'
import clsx from 'clsx'
import {useRouter} from 'next/router'
import {FC} from 'react'
import {st} from 'src/locales/TranslationHelper'

interface ICategoryGroupProps {
  categories: IBlogCategory[]
  className?: string
}

export const CategoryGroup: FC<ICategoryGroupProps> = ({categories, className}) => {
  const {query} = useRouter()
  return (
    <div className={clsx('max-w-2xl mx-auto mt-2', className)} aria-label={st.blogArchive.filterLabel()}>
      <ul className="hidden gap-2 md:grid md:grid-cols-3">
        {/* Edge case since the all posts page is not in the list of categories */}
        <li key="all-posts">
          <Link
            href="/blog"
            className={clsx(
              'p-2 rounded-md transition-colors  ease-in-out ',
              query.slug === undefined
                ? 'bg-purple-50 border-transparent !text-white hover:bg-indigo-700 hover:cursor-pointer'
                : 'bg-purple-20 border-gray-200 text-purple-90 hover:!text-white hover:cursor-pointer hover:bg-purple-50',
              'flex items-center justify-center text-sm font-medium  sm:flex-1 relative',
            )}
          >
            {st.blogArchive.allPosts()}
          </Link>
        </li>
        {categories.map(category => (
          <li key={category.title}>
            <Link
              href={category.slug}
              className={clsx(
                'p-2 rounded-md transition-colors  ease-in-out capitalize',
                query.slug === category.slug.replace('blog/category/', '')
                  ? 'bg-purple-50 border-transparent !text-white hover:bg-indigo-700  hover:cursor-pointer'
                  : 'bg-purple-20 border-gray-200 text-purple-90 hover:!text-white hover:cursor-pointer hover:hover:bg-purple-50',
                'flex items-center justify-center text-sm font-medium  sm:flex-1 relative',
              )}
            >
              {category.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
