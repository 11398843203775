import {SectionWrapper} from '@components/shared/SectionWrapper'
import {CustomersSection} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {FC} from 'react'
import {CustomerLogo} from '../CustomerLogosSection/CustomerLogo'

export interface IInlineCustomerLogosSectionProps {
  data: ResolvedSanityReferences<CustomersSection>
}

export const InlineCustomerLogosSection: FC<IInlineCustomerLogosSectionProps> = ({data}) => (
  <SectionWrapper backgroundStyle={data.backgroundStyle} id={data?.id}>
    <div className="items-center lg:grid lg:grid-cols-2 lg:gap-8 spare-container">
      <h2 className="max-w-md mx-auto text-3xl font-extrabold text-center text-gray-900 lg:max-w-xl lg:text-left">
        {data.title}
      </h2>
      <div className="self-center flow-root mt-8 lg:mt-0">
        {data?.customerLogos && (
          <div className="flex flex-wrap justify-between ">
            {data.customerLogos.map(logo => (
              <div
                key={logo._id}
                className="relative max-w-[100px] md:max-w-[175px] lg:max-w-[130px] xl:max-w-[175px] w-full "
              >
                <CustomerLogo key={logo._id} logo={logo.logo} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </SectionWrapper>
)
