import {ISanityIcon} from '@customTypes/types'
import {useSanityImage} from '@hooks/useSanityImage'
import Image from 'next/image'
import {FC} from 'react'

export interface IBlockItemProps {
  icon: ISanityIcon
  text: string
}

export const BlockItem: FC<IBlockItemProps> = ({icon, text}) => {
  const imageProps = useSanityImage(icon.asset, {enableBlurUp: false})
  return (
    <li className="flex items-center gap-3">
      <div className="flex">
        <Image {...imageProps} layout="fixed" height={28} width={28} placeholder="empty" key={icon.asset?._ref} />
      </div>
      <p className="text-gray-600 text-md">{text}</p>
    </li>
  )
}
