// page query

import {calendlySectionQuery} from '@components/Sections/CalendlySection'
import {callToActionQuery} from '@components/Sections/CallToActionSection'
import {comparisonBlockQuery} from '@components/Sections/ComparisonBlockSection'
import {customerMapSectionQuery} from '@components/Sections/CustomerMapSection'
import {eventSectionQuery} from '@components/Sections/EventsSection'
import {heroSectionQuery} from '@components/Sections/HeroSection'
import {highlightSectionQuery} from '@components/Sections/HighlightSection'
import {customersSectionQuery} from '@components/Sections/InlineCustomerLogosSection'
import {newsletterSignupSectionQuery} from '@components/Sections/NewsletterSignUpSection'
import {newsletterWithImageSignupQuery} from '@components/Sections/NewsletterWithImageSignup'
import {resourcesSectionQuery} from '@components/Sections/ResourcesSection'
import {simplePageSectionQuery} from '@components/Sections/SimplePageSection'
import {slideshowSectionQuery} from '@components/Sections/TabsSlideShowSection'
import {testimonialSectionQuery} from '@components/Sections/TestimonialSection'
import {textBlockSectionQuery} from '@components/Sections/TextAndImageSection'
import {textBannerSectionQuery} from '@components/Sections/TextBannerSection'
import {threeUpSectionQuery} from '@components/Sections/ThreeUpSection'
import {twoUpSectionQuery} from '@components/Sections/TwoUpSection/TwoUpSectionQuery'
import {videoSectionQuery} from '@components/Sections/VideoSection'
import {tabMenuQuery} from '@components/TabMenu'
import {groq} from 'next-sanity'
import {
  blogCardQuery,
  customerLocationQuery,
  getLocaleText,
  getPortableText,
  imageQuery,
  metaDataQuery,
  productUpdateQuery,
} from './fragments'

export const pageSectionsQueries = `
_type == 'callToAction' => {
  ${callToActionQuery()}
},
_type == 'comparisonBlock' => {
  ${comparisonBlockQuery()}
},
_type == 'customerMap' => {
  ${customerMapSectionQuery()}
},
_type == 'customersSection' => {
  ${customersSectionQuery()}
},
_type == 'newsletterSignup' => {
  ${newsletterSignupSectionQuery()}
},
_type == 'resourcesSection' => {
  ${resourcesSectionQuery()}
},
_type == 'slideshow' => {
  ${slideshowSectionQuery()}
},
_type == 'testimonialSection' => {
  ${testimonialSectionQuery()}
},
_type == 'textBlock' => {
  ${textBlockSectionQuery()}
},
_type == 'threeUpSection' => {
  ${threeUpSectionQuery()}
},
_type == 'twoUpSection' => {
  ${twoUpSectionQuery()}
},
_type == 'video' => {
  ${videoSectionQuery()}
},
_type == 'calendlySection' => {
  ${calendlySectionQuery()}
},
_type == 'newsletterWithImageSignup' => {
  ${newsletterWithImageSignupQuery()}
},
_type == 'textBannerSection' => {
  ${textBannerSectionQuery()}
},
_type == 'eventsSection' => {
  ${eventSectionQuery()}
}
`

export const genericPageQuery = groq`
*[(_type == 'page' || _type == 'simplePage') && slug.current == $slug] {
   _type == 'page' =>{
    ...,
    ${metaDataQuery},
    'hero': hero->{${heroSectionQuery()}},
    'tabMenu': tabMenu->{${tabMenuQuery()}},
    sections[]->{
        ${pageSectionsQueries}
    }
   },
   _type == 'simplePage' =>{
    ${simplePageSectionQuery()}
   }
}
`

export const homePageQuery = (): string => groq`
*[_type=="generalSiteSettings"][0].homePage-> {
    ...,
    ${metaDataQuery},
    'logos': *[_type == 'customerLogo' && showOnHomepage == true].solidLogo,
    'hero': hero->{${heroSectionQuery()}},
    'highlight': highlight->{${highlightSectionQuery()}},
    sections[]->{
        ${pageSectionsQueries}
    }
}
`

export const authorPageQuery = groq`
  {
    'author': *[_type == 'author' && slug.current == $slug][0]{
      ...,
      'description':${getLocaleText('description')},
      'profileImage':profileImage${imageQuery},
    },
    'posts': *[_type == 'blogPost' && author->slug.current == $slug]|order(_createdAt desc) {
      _id,
      'title':${getLocaleText('title')},
      'author':${getLocaleText('author')},
      'teaser':${getLocaleText('teaser')},
      'category':category->{'title':${getLocaleText('title')}},
      'image':image${imageQuery},
      publishedAt,
      slug
    }
  }
`

export const blogArchiveQuery = (
  initalBlogPostCount: number,
  category?: string,
) => groq`{'posts':*[_type == 'blogPost' ${
  category ? '&& category->slug.current == $categorySlug' : ''
}]|order(publishedAt desc)[0...${initalBlogPostCount}]{
 ${blogCardQuery}
},
'totalPosts': count(*[_type == 'blogPost' ${category ? '&& category->slug.current == $categorySlug' : ''}]),
'categories':*[_type == 'blogPostCategory']{
  _id,
  'title':${getLocaleText('title')},
  'slug': slug.current
  }
}
`

export const paginatedBlogPostsQuery = (category?: string) => groq`*[_type == 'blogPost' ${
  category ? '&& category->slug.current == $categorySlug' : ''
}]|order(publishedAt desc)[$offsetStart...$offsetEnd]{
  ${blogCardQuery}
  }`

export const blogPostQuery = groq`
*[_type == 'blogPost' && slug.current == $slug]{
...,
${metaDataQuery},
'teaser':${getLocaleText('teaser')},
'image':image${imageQuery},
'imageCaption':${getLocaleText('imageCaption')},
'category':category->{slug, 'title':${getLocaleText('title')}},
'author': author->{name, slug, profileImage${imageQuery}},
'text':${getPortableText('text')}
}
`

export const customerStoriesPageQuery = groq`
{
  'map': ${customerLocationQuery},
  'customerStories': *[_type == 'caseStudy']|order(_createdAt desc){
    _id,
    'teaser':${getLocaleText('teaser')},
    'title':${getLocaleText('title')},
    'image':image${imageQuery},
    'slug': slug.current
  }
}`

export const customerStoryPageQuery = groq`
*[_type == 'caseStudy' && slug.current == $slug]{
  ...,
  ${metaDataQuery},
  'teaser':${getLocaleText('teaser')},
  'serviceType': serviceType->{'title':${getLocaleText('title')}},
  'logo':logo${imageQuery},
  'image':image${imageQuery},
  'hero':hero->{
    ...,
    'title':${getLocaleText('title')},
    'heading':${getLocaleText('heading')},
    'logo':logo${imageQuery},
    'backgroundImage':backgroundImage${imageQuery},
    'text':${getPortableText('text')},
  },
  'text':${getPortableText('text')}
}
`

export const resourcesPageQuery = groq`
  {
    "blogPosts": *[_type == 'blogPost'] | order(publishedAt desc)[0...3]{
      ${blogCardQuery}
    },
    "customerStories": *[_type == 'caseStudy'] | order(_createdAt desc)[0...3] {
    _id,
    'teaser':${getLocaleText('teaser')},
    'title':${getLocaleText('title')},
    'image':image${imageQuery},
    slug
    }
  }
  `

export const productUpdatesPageQuery = groq`{
 "productUpdates":*[_type == 'productUpdate'] | order(publishedAt desc){
  ${productUpdateQuery}
  }
}
`

export const productUpdatePageQuery = groq`
*[_type == 'productUpdate' && slug.current == $slug]{
  ${productUpdateQuery}
}
`
