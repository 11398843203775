import {DeprecatedButton} from '@components/shared/DeprecatedButton'
import {Event as IEvent} from '@customTypes/generated-types'
import {ResolvedSanityReferences} from '@customTypes/types'
import {PortableTextComponents} from '@helpers/PortableTextSerializer'
import {useSanityImage} from '@hooks/useSanityImage'
import {PortableText} from '@portabletext/react'
import Image from 'next/image'
import React from 'react'

type EventProps = ResolvedSanityReferences<IEvent>

export const Event = ({location, name, description, image, button}: EventProps) => {
  const imageProps = useSanityImage(image.asset)

  return (
    <article className="relative grid gap-6 auto-rows-max lg:items-center lg:grid-cols-2 lg:gap-20 ">
      {/* Image Section */}
      <div className="rounded-[20px] overflow-hidden ">
        <div className=" aspect-w-16 aspect-h-9">
          <Image
            src={imageProps.src}
            layout="fill"
            sizes="'(min-width: 1024px) 650px, (min-width: 400px) 405px, 300px'"
            loader={imageProps.loader}
            placeholder="blur"
            className="rounded-lg"
            objectFit="cover"
            blurDataURL={imageProps.blurDataURL}
            alt={image.altText}
            key={image.asset._ref}
          />
        </div>{' '}
      </div>

      {/* Text Section */}
      <div>
        <span className="text-base font-semibold text-indigo-600 uppercase ">{location}</span>
        <h3 className="mt-4 text-xl font-bold text-gray-900 lg:text-4xl ">{name}</h3>
        <div className="mt-2 prose text-gray-600 lg:prose-xl">
          <PortableText value={description} components={PortableTextComponents} />
        </div>

        <DeprecatedButton
          className="block mt-6"
          id={button?.buttonId}
          buttonStyle={button?.buttonStyle}
          modalForm={button?.modalForm}
          buttonUrl={button?.buttonUrl}
        >
          {button?.buttonText}
        </DeprecatedButton>
      </div>
    </article>
  )
}
